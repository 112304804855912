import React, { useContext, createContext, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { RecoilRoot, useSetRecoilState, useRecoilState, useRecoilSnapshot } from 'recoil';

import { useRouteTracker } from '@fingermarkglobal/jitsu';
import {
  countState,
  serialState,
  systemState,
  settingsState,
  paymentState,
  transactionsState,
  menuIdState,
  currentMenuIdState,
} from '@fingermarkglobal/atoms';

import { Warning } from '../../../../components/stateless/warning';
import { lockService } from '../../../../services/lock';
import { loadingService } from '../../../../services/loading';
import { EnvironmentBadge } from '../../../../components/stateless/environment-badge';
import { PrimaryResolverView } from '../../resolvers/primary';
import { SecondaryResolverView } from '../../resolvers/secondary';

import { setUpSentryTags } from '../../../../utilities/sentry';

function RecoilDebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.log('Changed Atoms:');
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      // Uncomment line below to get the state (rather than just the name) of the modified atom.
      // Just be aware it can have a performance impact.
      // console.log(node.key, snapshot.getLoadable(node));
      console.log(node.key);
    }
  }, [snapshot]);
  return null;
}

const { loadingMachine } = loadingService();

const RootViewContext = createContext();

const RootViewProvider = ({
  resolve = true,
  children = null,
  resetHandler = null,
  ErrorComponent = Warning,
  overwriteSettings = {},
  resolverProps = {},
  retryTime = 30,
} = {}) => {
  logger.debug('cringer.components rendering application...');

  // Wrap in secondary `Provider` to allow the use of `useRecoilState`
  const Provider = ({
    children = null,
    resetHandler = null,
    ErrorComponent = () => {},
    overwriteSettings = {},
  }) => {
    const [state, send] = useMachine(loadingMachine);
    const [count, setCount] = useRecoilState(countState);
    const [serial, setSerial] = useRecoilState(serialState);
    const [system, setSystem] = useRecoilState(systemState);
    const [settings, setSettings] = useRecoilState(settingsState);
    const [payment, setPayment] = useRecoilState(paymentState);
    const [transactions, setTransactions] = useRecoilState(transactionsState);

    const setCurrentMenuId = useSetRecoilState(currentMenuIdState);
    const setMenuId = useSetRecoilState(menuIdState);

    const history = useHistory();
    const ldClient = useLDClient();

    const { lockMachine } = lockService();

    const [lockMachineState, lockMachineSend] = useMachine(lockMachine);

    useRouteTracker();

    const available = {
      resolve,
      send,
      state,
      count,
      setCount,
      serial,
      setSerial,
      system,
      setSystem,
      settings,
      overwriteSettings,
      setSettings,
      payment,
      setPayment,
      transactions,
      setTransactions,
      setCurrentMenuId,
      setMenuId,
      lockMachineState,
      lockMachineSend,
      resolverProps,
      retryTime,
    };

    const onError = resetHandler
      ? resetHandler
      : () => {
          if (payment?.info?.transactionId) {
            return history.push('/refund');
          }

          return history.push('/reset');
        };

    logger.updateAdditionalFields({
      fields: {
        serial: settings?.serial,
        storeName: settings?.settings?.site?.name,
        storeId: settings?.settings?.site?.storeId,
        kioskName: settings?.name,
      },
    });

    useEffect(() => {
      if (!!settings) {
        setUpSentryTags(settings);
      }

      if (!!settings && !!ldClient) {
        const { settings: configuration = {}, serial = '' } = settings;
        const { organisation = '', storeId = '' } = configuration.properties;

        ldClient.identify({
          kind: 'multi',
          organisation: {
            key: organisation,
          },
          store: {
            key: storeId,
          },
          kiosk: {
            key: serial,
          },
        });
      }
    }, [settings, ldClient]);

    return (
      <ErrorBoundary onError={onError} FallbackComponent={ErrorComponent}>
        <EnvironmentBadge settings={settings} />
        <RootViewContext.Provider value={available}>{children}</RootViewContext.Provider>
      </ErrorBoundary>
    );
  };

  return (
    <RecoilRoot>
      {process.env.NODE_ENV === 'development' && <RecoilDebugObserver />}
      <Provider
        resetHandler={resetHandler}
        ErrorComponent={ErrorComponent}
        overwriteSettings={overwriteSettings}
      >
        <PrimaryResolverView {...resolverProps}>
          <SecondaryResolverView {...resolverProps}>{children}</SecondaryResolverView>
        </PrimaryResolverView>
      </Provider>
    </RecoilRoot>
  );
};

// https://kentcdodds.com/blog/how-to-use-react-context-effectively
const useRootViewContext = () => {
  const context = useContext(RootViewContext);

  if (context === undefined) {
    throw new Error('useRootViewContext must be used within a RootViewProvider');
  }

  return context;
};

export { RootViewProvider, useRootViewContext };
