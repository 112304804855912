import { HeadersT, PaymentConfig, RequestT, SaleMessage, TransType } from '../types';

import { getCurrentFormattedDate } from '../utils';
import { parseTransactionAmout } from '../utils/parse-transaction-amount';
import { RequestBuilder } from './RequestBuilder';
export class SaleRequestBuilder extends RequestBuilder {
  constructor({
    amount,
    config,
    idempotencyKey,
    referenceNumber,
  }: {
    amount: number;
    config: PaymentConfig;
    idempotencyKey: string;
    referenceNumber: string;
  }) {
    super({ method: 'POST', amount, config, idempotencyKey, referenceNumber });
  }

  setBody(): void {
    const { settings, order, sessionId } = this.config;
    const { orderId } = order;
    const {
      responseChannelType,
      messageType,
      responsePayloadFormat,
      partialAuthFlag,
      merchantCurrencyCode,
      isLocal,
    } = settings;

    const currencyCode =
      merchantCurrencyCode && merchantCurrencyCode.trim() !== '' ? merchantCurrencyCode : 'USD';

    const { identifiers } = this.getIdentifiersFromPairingResponse();

    const saleMessage: SaleMessage = {
      responseChannelType,
      messageType,
      messageId: this.getIntegratorId(),
      responseChannel: {
        responsePayloadFormat,
      },
      message: {
        referenceNumber: this.referenceNumber,
        transType: TransType.Sale,
        transAmount: parseTransactionAmout(this.amount),
        taxList: [], //  Not required
        transactionDateTime: getCurrentFormattedDate(isLocal),
        identifiers,
        partialAuthFlag,
        cashierId: 'Cashier1', //TODO: Move to Poral this field
        currency: { merchantCurrencyCode: currencyCode },
      },
    };
    this.body = saleMessage;
  }

  getBody(): SaleMessage {
    return this.body;
  }
}
