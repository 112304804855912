import { HeadersT, Identifiers, PaymentConfig, RequestBuilderInterface, RequestT } from '../types';

export abstract class RequestBuilder implements RequestBuilderInterface {
  readonly method: string;
  readonly url: string;
  readonly config: PaymentConfig;
  readonly amount: number;
  readonly idempotencyKey: string;
  readonly referenceNumber: string;

  integratorId: string;
  hasPairingResponse: boolean = false;
  headers: HeadersT;
  body: any;
  deviceId: string;

  constructor({
    method,
    amount,
    config,
    idempotencyKey,
    referenceNumber,
  }: {
    method: string;
    amount: number;
    config: PaymentConfig;
    idempotencyKey: string;
    referenceNumber: string;
  }) {
    this.method = method;
    this.amount = amount;
    this.config = config;
    this.setDeviceId(config);
    this.url = this.getUrl(config);
    this.referenceNumber = referenceNumber;
    this.hasPairingResponse = this.validatePairingResponse();
    this.setIntegratorId(config);
    this.setHeaders({ config, idempotencyKey });
    this.setBody();
  }

  setDeviceId(config: any): void {
    const { settings } = config;
    const { pairingResponse = {} } = settings;
    const { deviceId } = pairingResponse?.deviceIdentifiers;
    if (!deviceId) {
      throw new Error('[Elavon] Invalid device ID - setDeviceId()');
    }
    this.deviceId = deviceId;
  }

  getUrl(config: any): string {
    const { settings } = config;
    const endpoint = `/devices/${this.deviceId}/message`;
    return `${process.env.POI_APP_CORS_PROXY}/${settings.url}${endpoint}`;
  }

  setIntegratorId(config: any): void {
    const { settings } = config;
    const { pairingSettings = {} } = settings;

    if (!pairingSettings?.pairingIntegratorId)
      throw new Error('[Elavon] - Missing Integrator ID - setIntegratorId()');

    this.integratorId = pairingSettings.pairingIntegratorId;
  }

  getIntegratorId(): string {
    return this.integratorId;
  }
  getDeviceId(): string {
    return this.deviceId;
  }
  setHeaders({ config, idempotencyKey }): void {
    const { settings } = config;
    this.headers = {
      'Accept-Version': settings.versionAPI,
      Authorization: '',
      'Cache-Control': 'no-cache',
      'Correlation-Id': idempotencyKey,
      'Content-Type': settings.messageType,
    };
  }

  setAuthorizationHeader(token: string): void {
    this.headers.Authorization = `Bearer ${token}`;
  }

  getHeaders(): HeadersT {
    return this.headers;
  }

  validatePairingResponse(): boolean {
    const { pairingResponse } = this?.config?.settings;
    if (!pairingResponse) {
      logger.error('[Elavon] "pairingResponse" is missing in "config.settings" object');
      return false;
    }
    if (!pairingResponse.metaData) {
      logger.error('[Elavon] "metaData" property is missing in "pairingResponse"');
      return false;
    }
    if (!Array.isArray(pairingResponse.metaData) || pairingResponse.metaData.length === 0) {
      logger.error('[Elavon] "metaData" is empty or not an array in "pairingResponse"');
      return false;
    }
    return true;
  }

  getMetaDataValue(key: string): string | undefined {
    if (!this.hasPairingResponse) return;
    const { pairingResponse } = this.config.settings;
    return pairingResponse.metaData.find(item => item?.key?.toUpperCase() === key?.toUpperCase())
      ?.value;
  }

  getIdentifiersFromPairingResponse(): { identifiers: Identifiers; isValid: boolean } {
    const chain = this.getMetaDataValue('CHAIN');
    const location = this.getMetaDataValue('LOCATION');
    const terminal = this.getMetaDataValue('TERMINAL');

    if (!chain) logger.error('[Elavon] "CHAIN" field is missing in "metaData"');
    if (!location) logger.error('[Elavon] "LOCATION" field is missing in "metaData"');
    if (!terminal) logger.error('[Elavon] "TERMINAL" field is missing in "metaData"');

    const isValid = !!chain && !!location && !!terminal;

    return {
      identifiers: {
        chain,
        location,
        terminal,
      },
      isValid,
    };
  }

  abstract setBody(): void;

  getRequest(): RequestT {
    const method = this.method;
    const url = this.url;
    const headers = this.getHeaders();
    const body = this.getBody();
    return {
      url,
      method,
      headers,
      data: body,
    };
  }

  abstract getBody(): any;
}
